// @ts-ignore
var $ = require('jquery');
// @ts-ignore
global.$ = global.jQuery = $;
// popper
// require('popper.js');
// bootstrap
// require('bootstrap');
// back to top
// require('./scripts/back-top.js');
// scripts
require('./scripts/app.js');
// require('./scripts/multi_form_group.js');
// styles
require('./styles/app.scss');
// fontawesome
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/css/fontawesome.min.css');
require('@fortawesome/fontawesome-free/css/brands.min.css');
require('@fortawesome/fontawesome-free/css/solid.min.css');
require('@fortawesome/fontawesome-free/css/regular.min.css');
require('@fortawesome/fontawesome-free');
